<template>
  <div class="main-panel">
    <h3 class="panel-title" v-if="!layerInfo?.id">供应商设置</h3>
    <el-button type="danger"
               size="mini"
               round
               class="save"
               :class="{'update-btn': layerInfo?.id}"
               :loading="isLoading"
               @click="save">保存
    </el-button>
    <el-form :model="form"
             :rules="formRules"
             ref="supplierForm"
             label-width="65px"
             @submit.prevent>
      <el-row :gutter="16">
        <el-col :span="12">
          <el-form-item label="名称" prop="name">
            <el-input v-model.lazy.trim="form.name" :size="controlSize" placeholder="名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="部门" prop="departmentId">
            <el-select :size="controlSize" filterable v-model="form.departmentId" placeholder="请选择部门">
              <el-option v-for="item in departments" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="16">
        <el-col :span="12">
          <el-form-item label="地址" prop="address">
            <el-input v-model.lazy.trim="form.address" :size="controlSize" placeholder="地址"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="电话" prop="phone">
            <el-input v-model.trim="form.phone"
                      :size="controlSize"
                      placeholder="电话"
                      oninput="value=value.replace(/[^\d,-]/g, '')"
                      maxlength="12"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="备注" prop="content">
        <el-input v-model.trim="form.content" :size="controlSize" placeholder="备注"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    layerInfo: {
      type: Object,
      default: () => {
      }
    }
  },

  data() {
    return {
      controlSize: "mini",
      form: {
        name: "",
        departmentId: null,
        address: "",
        phone: "",
        content: ""
      },

      formRules: {
        name: [{required: true, message: "请填写名称", trigger: "blur"}],
        department: [{required: true, message: "请选择部门", trigger: "change"}],
        address: [{required: true, message: "请填写地址", trigger: "blur"}],
        phone: [
          {min: 8, max: 12, message: "电话格式不正确", trigger: "blur"}
        ],
      },

      departments: [],

      isLoading: false
    }
  },

  async mounted() {
    this.departments = await this.$getList.getDepartments();
    if (this.layerInfo?.id) {
      this.getDetail()
    }
  },

  methods: {
    getDetail() {
      const detail = this.layerInfo
      this.form = {
        name: detail.name,
        departmentId: detail.departmentId,
        address: detail.address,
        phone: detail.phone,
        content: detail.content
      }
    },

    save() {
      this.$refs["supplierForm"].validate(async valid => {
        if (valid) {
          this.isLoading = true
          const sendData = {
            name: this.form.name,
            departmentId: this.form.departmentId,
            address: this.form.address,
            phone: this.form.phone,
            content: this.form.content
          }

          if (this.layerInfo?.id) {
            sendData.id = this.layerInfo.id
            const res = await this.$api.Base.Supplier.update(sendData).catch(() => {})
            this.isLoading = false
            if (res?.code === 200) {
              this.$message.success("修改成功");
              this.$emit("handle")
            } else {
              this.$message.error(res?.msg || "修改失败");
            }
          } else {
            const res = await this.$api.Base.Supplier.add(sendData).catch(() => {})
            this.isLoading = false
            if (res?.code === 200) {
              this.$message.success("新增成功");
              this.$refs["supplierForm"].resetFields();
            } else {
              this.$message.error(res?.msg || "新增失败");
            }
          }
        }
      })
    }
  }
}
</script>
